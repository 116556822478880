import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Font, TextBlock } from '@politechdev/blocks-design-system'
import { Grid, Section, DetailItem, Sheet, SectionLabel } from 'components/'
import GalleryViewOptions from 'qualityControl/pledgeCardGallery/GalleryViewOptions/GalleryViewOptions'
import { usePledgeCardGalleryState } from 'qualityControl/pledgeCardGallery/PledgeCardGalleryContext/PledgeCardGalleryContext'
import styles from 'qualityControl/pledgeCardGallery/PledgeCardGallery.module.scss'
import classNames from 'classnames/bind'
import { CoverPdfViewer } from 'qualityControl/scanReview/PdfViewer'

const cx = classNames.bind(styles)

const GallerySubheader = ({ expanded }) => {
  const { t } = useTranslation()
  const { packet } = usePledgeCardGalleryState()

  return (
    <Sheet>
      <Grid
        className={cx('subheader__details', {
          'subheader__details--expanded': expanded,
        })}
      >
        <Section>
          <DetailItem label="Canvasser">
            {packet.canvasser.full_name}
          </DetailItem>
          <DetailItem label="Shift date">
            {moment(packet.shift.shift_start).format('MMM D, YYYY')}
          </DetailItem>
          <TextBlock>
            <Font.Copy variant="secondary">{t('Look out for')}</Font.Copy>
          </TextBlock>
          <TextBlock>
            <ol>
              <li>
                {t(
                  'Technical issues that will require packet deletion (unredacted forms, redactions that are covering too much, blurry scans, etc.)'
                )}
              </li>
              <li>
                {t(
                  'Trends across forms like handwriting that needs attention, missing phone numbers, etc'
                )}
              </li>
            </ol>
          </TextBlock>
        </Section>
        <div className={styles.coversheet__container}>
          <TextBlock>
            <Font.Copy variant="highlight">{t('Cover Sheet')}</Font.Copy>
          </TextBlock>
          <CoverPdfViewer
            url={packet.coverSheet.file_url}
            removedAt={packet.fileLocator?.redacted_at}
          />
        </div>
      </Grid>
      <div className={styles.subheader__main}>
        <SectionLabel>{t('Pledge Cards')}</SectionLabel>
        {!expanded && <GalleryViewOptions />}
      </div>
    </Sheet>
  )
}

export default GallerySubheader
