import { formCompositePdf, isError } from 'utils/pdfPagesToComposite'

type FileUrl = {
  file_url: string
}

type ScanUrls = FileUrl & {
  form?: {
    pledge_card_url?: string
  }
}

export const getUrls = <T extends ScanUrls>(scanUrls: T): string[] | string =>
  scanUrls.form?.pledge_card_url
    ? [scanUrls.file_url, scanUrls.form.pledge_card_url]
    : scanUrls.file_url

export const getPdf = async <T extends ScanUrls, U extends FileUrl>(
  scanUrls: T[],
  coverSheetUrl: U
): Promise<string | undefined> => {
  const urls = [coverSheetUrl.file_url].concat(scanUrls.flatMap(getUrls))
  const pdfDoc = await formCompositePdf(urls)

  if (!isError(pdfDoc)) {
    return pdfDoc.saveAsBase64({ dataUri: true })
  }
}
