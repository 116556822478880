import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentPacket } from 'qualityControl/contexts/PacketContext'
import { FlagPacketViewer } from '../scanReview/PacketViewer'
import FlagSinglePacketList from './FlagSinglePacketList'
import { useCurrentFlagContext } from './contexts/CurrentFlagContext'
import { getFlagPackets } from './utils'

const FlagSinglePacketViewer = ({ refProp }) => {
  const { t } = useTranslation()

  const { currentFlag } = useCurrentFlagContext()
  const packets = getFlagPackets(currentFlag)
  const packetIds = packets.map(({ id }) => id)
  const [currentPacket, setPacketId] = useCurrentPacket()
  const packetId = currentPacket?.id
  const isValidPacket = packetId && packetIds.includes(packetId)

  const firstPacketId = packetIds.at(0)

  useEffect(() => {
    if (currentFlag) {
      setPacketId(isValidPacket ? packetId : firstPacketId, true)
    }
  }, [currentFlag?.id])

  const headerMessage = `${t('Packet')}${packetIds.length ? 's' : ''} ${t(
    'that triggered this issue'
  )}`
  const sidebarStatusClassName = isValidPacket ? 'inbox-viewer--open' : ''

  return (
    <div ref={refProp}>
      <div className="inbox-viewer__sidebar__header--full-width">
        <h2>{headerMessage}</h2>
      </div>
      <div className={`inbox-viewer ${sidebarStatusClassName}`}>
        <div className="inbox-viewer__main">
          <FlagSinglePacketList />
        </div>
        <div className="inbox-viewer__sidebar">
          {isValidPacket && <FlagPacketViewer isOpen />}
        </div>
      </div>
    </div>
  )
}

export default FlagSinglePacketViewer
