/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState, useMemo, useCallback } from 'react'
import { Document, Page } from 'react-pdf/dist/entry.webpack'
import PropTypes from 'prop-types'
import { Button, Paginator, InteractionResponder } from 'components'
import { useMounted } from 'hooks'
import { noop } from 'lodash/util'
import classNames from 'classnames/bind'
import { Font, Icon } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { uniqueId } from 'lodash'
import { Timestamp } from 'components/Time/Timestamp'
import styles from './PdfViewer.module.scss'

const cx = classNames.bind(styles)

const DEFAULT_PLACEHOLDER_WIDTH = 800

const DEFAULT_PLACEHOLDER_ASPECT_RATIO = 8.5 / 11

const LoadingPlaceholder = ({ width, height, loadingMessage }) => (
  <div
    className={styles.loading}
    style={{
      height: `${height}px`,
      width: `${width}px`,
      maxWidth: '100%',
    }}
  >
    <p>{loadingMessage}</p>
  </div>
)

const RemovedFile = ({ removedAt }) => {
  const { t } = useTranslation()
  return (
    <div className={styles['por-removed']}>
      <Font.Display variant="small">{t('Original scan expired')}</Font.Display>
      <Font.Copy variant="hint">
        {t('This scan was automatically removed on')}{' '}
        <Timestamp
          dateTime={removedAt}
          formatOptions={{ dateStyle: 'medium', timeStyle: undefined }}
        />
      </Font.Copy>
      <Icon.FileSlash className={styles['por-removed--icon']} />
    </div>
  )
}

const PreLoader = () => (
  <div
    className={cx(styles.viewer, styles['viewer--loading'])}
    style={{
      height: DEFAULT_PLACEHOLDER_WIDTH / DEFAULT_PLACEHOLDER_ASPECT_RATIO,
    }}
  />
)

const PdfViewer = ({
  CustomHeader,
  headerProps,
  showPaginator,
  showScanNumber,
  scanNumber,
  showDownload,
  isInteractive,
  removedAt,
  pageNumber: controlledPageNumber,
  onNext,
  onPrevious,
  onLoadSuccess,
  width,
  file,
  filename,
  documentLoadingMessage,
  pageLoadingMessage,
  className,
  disabled,
}) => {
  const [numPages, setNumPages] = useState()
  const [uncontrolledPageNumber, setUncontrolledPageNumber] = useState(1)
  const [placeholderHeight, setPlaceholderHeight] = useState(
    width / DEFAULT_PLACEHOLDER_ASPECT_RATIO
  )

  const pageRef = useRef(null)
  const isMounted = useMounted()

  const isControlled = useMemo(
    () => !!controlledPageNumber,
    [controlledPageNumber]
  )

  const pageNumber = useMemo(
    () => (isControlled ? controlledPageNumber : uncontrolledPageNumber),
    [isControlled, controlledPageNumber, uncontrolledPageNumber]
  )

  const documentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    onLoadSuccess && onLoadSuccess()
  }

  const nextClick = () => {
    isControlled
      ? onNext()
      : setUncontrolledPageNumber(uncontrolledPageNumber + 1)
  }

  const previousClick = () => {
    isControlled
      ? onPrevious()
      : setUncontrolledPageNumber(uncontrolledPageNumber - 1)
  }

  const getPlaceholderHeight = () => {
    const pageHeight = pageRef && pageRef.current.clientHeight
    isMounted && setPlaceholderHeight(pageHeight)
  }

  const loadDocument = useCallback(
    ({ enableResponder } = {}) => (
      <Document
        file={removedAt ? undefined : file}
        onLoadSuccess={e => {
          documentLoadSuccess(e)
          enableResponder && enableResponder()
        }}
        loading={
          <LoadingPlaceholder
            width={width}
            height={placeholderHeight}
            loadingMessage={documentLoadingMessage}
          />
        }
        noData={
          removedAt ? (
            <RemovedFile removedAt={removedAt} />
          ) : (
            'Failed to load PDF file.'
          )
        }
        className={cx('document', {
          'document--with-header': showDownload,
          'document--with-footer': showPaginator,
        })}
      >
        <Page
          inputRef={ref => {
            pageRef.current = ref
          }}
          onRenderSuccess={getPlaceholderHeight}
          pageNumber={pageNumber}
          renderAnnotations={false}
          width={width}
          loading={
            <LoadingPlaceholder
              width={width}
              height={placeholderHeight}
              loadingMessage={pageLoadingMessage}
            />
          }
          className={styles.page}
        />
      </Document>
    ),
    [pageNumber, width, placeholderHeight, pageLoadingMessage, removedAt, file]
  )

  return (
    <div className={cx('viewer', className)}>
      {CustomHeader && <CustomHeader {...headerProps} />}
      {showDownload && (
        <div className={styles.download}>
          <a
            href={file}
            download={filename || `${uniqueId()}.pdf`}
            target="_blank"
            rel="noopener noreferrer"
            type="application/pdf"
          >
            <Button
              icon
              key="download"
              tooltipLabel="Download"
              tooltipPosition="left"
            >
              save_alt
            </Button>
          </a>
        </div>
      )}
      {isInteractive ? (
        <InteractionResponder waitForTrigger>
          {loadDocument}
        </InteractionResponder>
      ) : (
        loadDocument()
      )}
      {showScanNumber && (
        <div className={styles.paginator}>{`Scan #${scanNumber}`}</div>
      )}
      {showPaginator && (
        <Paginator
          currentPage={pageNumber}
          totalPages={numPages || 0}
          onNext={nextClick}
          onPrevious={previousClick}
          className={styles.paginator}
          justify="center"
          disabled={disabled}
        />
      )}
    </div>
  )
}

PdfViewer.propTypes = {
  CustomHeader: PropTypes.any,
  headerProps: PropTypes.any,
  showPaginator: PropTypes.bool,
  showDownload: PropTypes.bool,
  isInteractive: PropTypes.bool,
  pageNumber: PropTypes.number,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onLoadSuccess: PropTypes.func,
  width: PropTypes.number,
  file: PropTypes.string.isRequired,
  documentLoadingMessage: PropTypes.string,
  pageLoadingMessage: PropTypes.string,
  className: PropTypes.string,
}

PdfViewer.defaultProps = {
  CustomHeader: null,
  headerProps: {},
  showPaginator: false,
  showDownload: false,
  isInteractive: false,
  pageNumber: undefined,
  onNext: noop,
  onPrevious: noop,
  onLoadSuccess: noop,
  width: DEFAULT_PLACEHOLDER_WIDTH,
  documentLoadingMessage: 'Pdf loading...',
  pageLoadingMessage: '',
  className: '',
}

PdfViewer.PreLoader = PreLoader
export default PdfViewer
