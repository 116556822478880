import { PDFDocument } from 'pdf-lib'
import { Error } from 'types/errorHandling/error'

export const isError = (
  o: ArrayBuffer[] | PDFDocument[] | PDFDocument | Error
): o is Error => (o as Error).error

export const formPdfBuffers = async (
  pageUrls: string[]
): Promise<ArrayBuffer[] | Error> => {
  try {
    const calls = await Promise.all(pageUrls.map(p => fetch(p)))
    return await Promise.all(calls.map(c => c.arrayBuffer()))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    return { error: true }
  }
}

export const loadPdfDocs = async (
  buffers: ArrayBuffer[]
): Promise<PDFDocument[] | Error> => {
  try {
    return await Promise.all(buffers.map(b => PDFDocument.load(b)))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    return { error: true }
  }
}

export const formCompositePdf = async (
  urls: string[]
): Promise<PDFDocument | Error> => {
  try {
    const pdfDoc = await PDFDocument.create()
    const buffers = await formPdfBuffers(urls)
    if (isError(buffers)) return buffers
    const docs = await loadPdfDocs(buffers)
    if (isError(docs)) return docs
    const pages = await Promise.all(docs.map(d => pdfDoc.copyPages(d, [0])))
    pages.forEach(p => pdfDoc.addPage(p[0]))
    return pdfDoc
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    return { error: true }
  }
}
