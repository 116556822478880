import { useState } from 'react'
import { Paginator } from 'components'
import {
  SelectField,
  PageHeader,
  TextBlock,
  ProgressBar,
  FieldBlock,
  Sheet,
  List,
  ContentBlock,
  Font,
} from '@politechdev/blocks-design-system'
import { putScan } from 'requests/shifts'
import { useTranslation } from 'react-i18next'
import { useRoutePathParams } from 'hooks/router'
import styles from './RegistrationFormTable.module.scss'

const ScanActions = ({
  scan,
  scanCounties,
  setScanCounties,
  countyOptions,
}) => {
  const { t } = useTranslation()
  return (
    <FieldBlock className={styles.actions}>
      <SelectField
        label={t('County')}
        key="county-control"
        disabled={scan.delivery_id}
        value={scanCounties[scan.id]}
        onSelect={val => {
          setScanCounties(oldScanCounties => ({
            ...oldScanCounties,
            [scan.id]: val,
          }))
          putScan(scan.id, {
            county: val,
          })
        }}
        options={countyOptions}
      />
    </FieldBlock>
  )
}

const RegistrationFormTable = ({ scans, countyOptions, loading }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [scanCounties, setScanCounties] = useState({})
  const [{ id: shiftId, shiftType }] = useRoutePathParams()
  const { t } = useTranslation()
  const pageSize = 5
  const paginate = increment => {
    setCurrentPage(oldCurrentPage => oldCurrentPage + increment)
  }

  const scanPage = scans.slice(
    currentPage * pageSize - pageSize,
    pageSize * currentPage
  )

  return (
    <Sheet className="margin--bottom">
      <PageHeader title={t('Registration forms')} />
      <TextBlock className="reading-text">
        <p>
          {t(
            'Scans are shown starting from the second page of the PDF attached to this shift.'
          )}
        </p>
      </TextBlock>
      <ProgressBar show={loading} />
      <List
        itemData={scanPage}
        emptyMessage={t('No registration forms uploaded')}
        render={scan => {
          if (scanCounties[scan.id] === undefined) {
            setScanCounties(oldScanCounties => ({
              ...oldScanCounties,
              [scan.id]: scan.county,
            }))
          }

          return (
            <List.Item>
              <ContentBlock>
                <a
                  href={`/collect/${shiftType}/shifts/${shiftId}/packet/scans/${scan.id}?source=shifts&sourceId=${shiftId}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {`${t('Scan')} #${scan.scan_number}`}
                  {scan.form
                    ? ` - ${scan.form.display_name || `[No name]`}`
                    : null}
                </a>
                <Font.Copy variant="secondary">
                  {scan.delivery_id
                    ? `${t('On delivery')} # ${scan.delivery_id}`
                    : t('Not on a delivery')}
                </Font.Copy>
              </ContentBlock>
              <ScanActions
                scan={scan}
                scanCounties={scanCounties}
                setScanCounties={setScanCounties}
                countyOptions={countyOptions}
              />
            </List.Item>
          )
        }}
      />
      {scans && scans.length > 0 && (
        <Paginator
          onNext={() => {
            paginate(+1)
          }}
          onPrevious={() => {
            paginate(-1)
          }}
          currentPage={currentPage}
          totalPages={Math.ceil(scans.length / pageSize)}
        />
      )}
    </Sheet>
  )
}

export default RegistrationFormTable
